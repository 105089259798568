import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuVorauswahlMenuname'
})
export class MenuVorauswahlMenunamePipe implements PipeTransform {

  transform(menuId: string, tag): string | boolean {
    if (tag.tagesMenues[menuId].menueText !== ''){
      if (menuId.includes('M')){
        return tag.tagesMenues[menuId.replace('M', '')].menueText
      }
      return tag.tagesMenues[menuId].menueText
    }
    return false
  }

}
