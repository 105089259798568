<div id="vorauswahlEditorContainer">
  <div id="vorauswahlEditorTitelContainer">
    <strong id="vorauswahlEditorTitel">Komponentenauswahl</strong>
<!--    <mat-icon (click)="verwerfeVorauswahl()">close</mat-icon>-->
  </div>
  <div id="editorContent">
    <div *ngFor="let menuOptionen of menu.optionen | keyvalue" class="vorauswahlEditorKomponenten" class="editorCard">
      <div class="editorTitel">
        <div id="vorauswahlEditorLabel">
          <strong *ngIf="menuOptionen.value.pos == 1">Hauptkomponente</strong>
          <strong *ngIf="menuOptionen.value.pos != 1">Komponente {{ menuOptionen.key - 1 }}</strong>
        </div>
      </div>
      <mat-chip-listbox id="editorChipList" (change)="aktualisiereVorauswahl($event, menu.menueNr, menuOptionen.value)">
        <div id="editorExpansionContent">
          <mat-chip-option
            [selected]="!(buffer.menues[menu.menueNr] && buffer.menues[menu.menueNr][menuOptionen.value.pos])"
            (click)="zuruecksetzen(menu.menueNr, menuOptionen.value.pos)"
            class="editorCardContent"
          >
            <!--TODO nach dem angular 17 update einbinden matTooltip="Keine Auswahl" matTooltipClass="zuruecksetzenTooltip" matTooltipPosition="above"-->
            <div class="keineAuswahl">
              <mat-icon>no_meals</mat-icon>
            </div>
          </mat-chip-option>
          <ng-container *ngFor="let menuId of menuOptionen.value.menueIds">
            <mat-chip-option
              *ngIf="menuId | menuVorauswahlMenuname:aktuelleMenuVorauswahl[data.tag.datum]"
              class="editorCardContent"
              [value]="[menuId, menuId | menuVorauswahlMenuname:aktuelleMenuVorauswahl[data.tag.datum] | bbCode]"
              [selected]="buffer.menues && buffer.menues[menu.menueNr] && buffer.menues[menu.menueNr][menuOptionen.value.pos] == menuId"
              [disabled]="hauptkomponenteBereitsGewaehlt(buffer, menuId, menuOptionen.value.pos, menu.menueNr)"
            >
              <span class="editorCardContentSpan" [innerHTML]="menuId | menuVorauswahlMenuname:aktuelleMenuVorauswahl[data.tag.datum] | bbCode"></span>
            </mat-chip-option>
          </ng-container>
        </div>
      </mat-chip-listbox>
    </div>
  </div>
  <div id="vorauswahlEditorOptionen">
    <button id="abbrechen" (click)="verwerfeVorauswahl()">Abbrechen</button>
    <button id="reset" (click)="setzeVorauswahlzurueck(menu.menueNr)">Auswahl zurücksetzen</button>
    <button id="speichern" (click)="speichereVorauswahl()">Menü Speichern</button>
  </div>
</div>
