import {Injectable} from '@angular/core';
import {AsyncSubject, BehaviorSubject, Observable, Subscription, zip} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {MitteilungenDialogComponent} from './mitteilungen-dialog/mitteilungen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';
import {abweichungenGlobal, abweichungenKunde} from './mitteilungen.interface';
import {map} from 'rxjs/operators';


@Injectable()
export class MitteilungenService {

  public mitteilungen_kunde= new BehaviorSubject({
    global: '',
    mandant: '',
    kunde: '',
    einrichtung: ''
  })

  public mitteilungen_global= new BehaviorSubject({
    global: '',
    mandant: ''
  })

  public abweichungenKunde = {
  global: '',
  mandant: '',
  kunde: '',
  einrichtung: '',
}

public abweichungenGlobal = {
  global: '',
  mandant: ''
}

  public mitteilungenUpdate = new BehaviorSubject(false)
  public mitteilungenVorhanden = false
  public kundeSubscription: Subscription;
  public kunde
  public abweichung = {}

  constructor(
    private kundenService: KundeService,
    private matDialog: MatDialog,
    private contentBlockService: ContentBlockService,
  ) {

  }

   public holeMitteilungen(){
    this.kundeSubscription = this.kundenService.update.subscribe(() => {
      this.kundenService._kunde_get().subscribe(kunde => {
        this.kunde = kunde
        this.holeContentbloecke(kunde).subscribe(() => {
          let mitteilungen
          if (kunde) {
            mitteilungen = this.mitteilungen_kunde.value
            let speicher = this.mitteilungenKundePruefen()
            mitteilungen['einrichtung'] = kunde.einrichtung.info != '' ? kunde.einrichtung : speicher['einrichtung']
            mitteilungen['kunde'] = localStorage.getItem('active')
            this.mitteilungen_kunde.next(mitteilungen)
          } else {
            mitteilungen = this.mitteilungen_global.value
            this.mitteilungen_global.next(mitteilungen)
          }
          this.mitteilungenAbgleich()
        })
      })
    })
  }

  public holeContentbloecke(kunde): Observable<any>{
    let suffix = environment.type == 'web' ? 'Web' : 'App'
    return zip(
      this.contentBlockService.getContentBlock(`${suffix}_MandantenMessage`),
      this.contentBlockService.getContentBlock(`${suffix}_GlobalMessage`)
    ).pipe(map((contentBlocks: Array<any>) => {
      if (contentBlocks[0] ? contentBlocks[0].length > 0 : false || contentBlocks[1] ? contentBlocks[1].length > 0 : false){
        let mitteilungen:any = kunde ? this.mitteilungen_kunde.value : this.mitteilungen_global.value;
        mitteilungen['mandant'] = contentBlocks[0]
        mitteilungen['global'] = contentBlocks[1]
        if (kunde){
          this.mitteilungen_kunde.next(mitteilungen)
        } else {
          this.mitteilungen_global.next(mitteilungen)
        }
      }
    }))
  }

  public mitteilungenAbgleich(){
    let mitteilungen = this.kunde ? this.mitteilungen_kunde.getValue() : this.mitteilungen_global.getValue()
    this.abweichung = this.kunde ? this.abweichungenKunde : this.abweichungenGlobal
    let speicher: any = this.kunde ? this.mitteilungenKundePruefen() : JSON.parse(localStorage.getItem('mitteilungen_global'))
    if (speicher){
      for (const key in mitteilungen){
        if (key == 'einrichtung'){
          if (mitteilungen[key]){
            if (mitteilungen[key]['info'] != speicher[key]['info'] && mitteilungen[key]['info'] != ''){
              this.abweichung[key] = true;
            } else {
              this.abweichung[key] = false;
            }
          }
        } else if (mitteilungen[key] != speicher[key] && mitteilungen[key] != '' && key != 'einrichtung'){
          this.abweichung[key] = true;
        } else {
          this.abweichung[key] = false;
        }
      }
      this.abweichungPruefen()
    } else {
      for (const key in mitteilungen){
        this.abweichung[key] = true;
      }
      if (!this.mitteilungenUpdate.getValue()){
        this.mitteilungenUpdate.next(true)
      }
    }
  }

  public abweichungPruefen(){
    for (let value in this.abweichung){
      if (this.abweichung[value] == true){
        if (!this.mitteilungenUpdate.getValue()){
          this.mitteilungenUpdate.next(true)
        }
      }
    }
  }

  public dialogOpen(){
    if (environment.type != 'web'){
      if (localStorage.getItem('readTutorialMenu') || localStorage.getItem('redIosPwaInstall')){
        this.matDialog.open(MitteilungenDialogComponent, {
          disableClose: true,
          data: {
            vorhanden: this.mitteilungenVorhanden
          }
        })
      }
    } else {
      this.pruefeMitteilungenObLeer()
      this.matDialog.open(MitteilungenDialogComponent, {
        disableClose: true,
        data: {
          vorhanden: this.mitteilungenVorhanden
        }
      })
    }
  }

  public mitteilungenKundePruefen(){
    let gespeichert = JSON.parse(localStorage.getItem('mitteilungen_kunde'))
    if (gespeichert){
      for (let kundeSammlung of gespeichert){
        if (kundeSammlung.kunde == localStorage.getItem('active')){
          return kundeSammlung
        }
      }
      return false
    } else {
      return false
    }
  }


  public mitteilungenKundeAktualisieren(){
    let kundenSammlung = []
    let item = localStorage.getItem('mitteilungen_kunde')
    if (item){
      kundenSammlung = JSON.parse(item)
      let gespeichert = this.mitteilungenKundePruefen()
      if (gespeichert){
        const index = kundenSammlung.findIndex(kundeObj => kundeObj.kunde === localStorage.getItem('active'));
        if (index !== -1) {
          kundenSammlung[index] = this.mitteilungen_kunde.value;
        }
      } else {
        kundenSammlung.push(this.mitteilungen_kunde.value)
      }
    } else {
      kundenSammlung.push(this.mitteilungen_kunde.value)
    }
    localStorage.setItem('mitteilungen_kunde', JSON.stringify(kundenSammlung))
    this.setzeWerteAufFalse(this.abweichung)
    this.mitteilungenUpdate.next(false)
  }

  public mitteilungsZustandSichern(){
    if (!this.kunde){
      localStorage.setItem('mitteilungen_global', JSON.stringify(this.mitteilungen_global.value))
      this.mitteilungenUpdate.next(false)
    } else {
      this.mitteilungenKundeAktualisieren()
    }
  }

  public pruefeMitteilungenObLeer(){
    if (this.kunde){
      this.mitteilungenVorhanden = false
      for (let key in this.mitteilungen_kunde.getValue()) {
        if (this.mitteilungen_kunde.getValue()[key] == 'einrichtung' && key != 'kunde') {
          if (this.mitteilungen_kunde.getValue()[key].info !== '') {
            this.mitteilungenVorhanden = true
          }
        } else if (this.mitteilungen_kunde.getValue()[key] !== '' && this.mitteilungen_kunde.getValue()[key] != undefined && key != 'kunde'){
          this.mitteilungenVorhanden = true
        }
      }
    } else {
      for (let mitteilungen of Object.values(this.mitteilungen_global.getValue())){
        if (mitteilungen != ''){
          this.mitteilungenVorhanden = true
        }
      }
    }
    return this.mitteilungenVorhanden
  }

  public setzeWerteAufFalse(obj: any): void {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = false;
      }
    }
  }
}
