import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { BehaviorSubject, of, zip } from 'rxjs';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import {NavigationEnd, Router} from '@angular/router';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import { ConfirmDialogComponent } from '../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import { MatDialog } from '@angular/material/dialog';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';
import { slideInOutAnimation } from './animations';
import {FarbkatalogService} from '../../../services/farbkatalog.service';
import {WebFarbkatlogComponent} from '../web-farbkatalog/web-farbkatalog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';
import {FaqService} from '../../../submodul/obs.shared/componenten/faq/faq.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'web-toolbar',
  templateUrl: './web-toolbar.component.html',
  styleUrls: ['./web-toolbar.component.css'],
  animations: [slideInOutAnimation]
})


export class WebToolbarComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public mandant_service: MandantenService,
    public kunde_service: KundeService,
    public bestell_service: BestellungService,
    public mitteilungsService: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
    public farbkatalogService: FarbkatalogService,
    private dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    public faqService: FaqService,
  ) { }

  @ViewChild('trigger') trigger: ElementRef
  @ViewChild('benutzerMenu') benutzerMenu: ElementRef<any>
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  public mandant: any
  public kunde: object | boolean
  public data = new BehaviorSubject<any>(false)
  public item: string
  public message: any
  public content: string
  public flag: any
  public guthaben
  public mandantId
  public isProfileActive = false;
  public enviroment
  public aktuelleUrl


  ngOnInit() {
    this.enviroment = environment
  }

  ngAfterViewInit() {
    // this.benutzerMenu.nativeElement.overlayPanelClass = 'custom'
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        console.log(this.menuTrigger)
        this.isProfileActive = this.router.url === '/profil?id=einsehen';
      }
    });
    this.mandant_service.mandant_get().subscribe(mandant => {
      this.mandant = mandant;
    })
    this.kunde_service.update.subscribe(kundeUpdate => {
      this.kunde_service._kunde_get().subscribe((kunde) => {
        this.kunde = kunde
        if (kunde){
          this.mandantId = kunde.mandantId
          this.guthaben = kunde.guthaben
          this.benachrichtigung_service.setze_benachrichtigungen(kunde)
        }
      })
    })
    this.mitteilungsService.benachrichtigungen.subscribe(item =>{
      this.flag = item
    })
  }

  ngOnDestroy() {
    this.kunde_service.kundeGuthaben.unsubscribe()
  }

  setLinkActive(value) {
    if (value == 1) {
      let button = document.getElementById("rechtliches")
      button.classList.add('active')
    }
    else if (value == 2) {
      let button = document.getElementById("profil")
      button.classList.add('active')
    }
  }

  setLinkInactive(value) {
		if (value != null) {
			if (value == 1) {
			}
			else if (value == 2) {
				let button2 = document.getElementById("profil")
				button2.classList.remove('active')
			}
			else {
				let button2 = document.getElementById("profil")
				button2.classList.remove('active')
			}
		}
  }

  public get() {
    zip(
      this.mandant_service.mandant_get(),
      this.kunde_service._kunde_get()
    ).subscribe((result: any) => {
      this.mandant = result[0]
      this.kunde = result[1]
      this.data.next({
        mandant: result[0],
        kunde: result[1]
      })
    })
  }

  public home_link(): any{
    if ((this.kunde && this.mandant.navigation_logged_in.home_content) || (!this.kunde && this.mandant.navigation_root.home_content)){
      this.router.navigateByUrl('/home')
    }
    if ((this.kunde && this.mandant.navigation_logged_in.home_link) || (!this.kunde && this.mandant.navigation_root.home_link)){
      window.open(this.mandant.homeLink,"_blank");
    }
  }

  public home_config(){
    if ((this.kunde && this.mandant.navigation_logged_in.home_content) || (!this.kunde && this.mandant.navigation_root.home_content)){
      return true
    }
    if ((this.kunde && this.mandant.navigation_logged_in.home_link) || (!this.kunde && this.mandant.navigation_root.home_link)){
      return true
    }
    return false
  }

  public registrationLink(){
    if (this.mandant.darstellungRegistrierung == 2){
      return '/register-zwischenseite'
    }else{
      if (this.mandant.registrierFormen[Object.keys(this.mandant.registrierFormen)[0]].registrierArt == 1){
        return `/register-info/${Object.keys(this.mandant.registrierFormen)[0]}`
      }
      return `/register-form/${Object.keys(this.mandant.registrierFormen)[0]}`
    }
  }

  public logout(id:string){
    let confirm: any = of(true)
    if (this.bestell_service._bestellungen_meta.anzahl > 0) {
      confirm = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "",
          message: `Sie haben noch offene Bestellvorgänge. Möchten Sie trotzdem fortfahren?`,
          panelClass: 'dialog-container-bestellschluss',
        }
      }).afterClosed();
    }
    confirm.subscribe((result) => {
      if (result){
        this.bestell_service.bestellungen_leeren()
        this.kunde_service.kunde_logout(id).subscribe(() => {
          let navigation = this.mandant.navigation_root_default
          if (navigation == 'home') {
            this.router.navigateByUrl('/home');
          }
          else if (navigation == 'login') {
            this.router.navigateByUrl('/login');
          }
          else {
            this.router.navigateByUrl('/speiseplan-overview');
          }
          this.mitteilungsService.setMitteilung('1004');
          this.faqService.faqAnzeigen(null)
        })
      }
    })
  }


  farbkatalogAktiv(){
    return this.farbkatalogService.mandanten.includes(environment.mandant_id)
  }


  toggleSidebar() {
    // this.sidebarState = this.sidebarState === 'out' ? 'in' : 'out';

    this.snackBar.openFromComponent(WebFarbkatlogComponent, {
      duration: undefined,
    })
  }

  protected readonly environment = environment;
}

