import {NavigationEnd, Router} from '@angular/router';
import {timer} from 'rxjs';
import {KundeService} from '../kunden/kunde.service';
import {MitteilungsService} from '../prozesse/mitteilung.service';
import {RevisionService} from '../api/revision.service';
import {Injectable} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import { RouterListe } from 'services/router.class';
import {FaqService} from '../../submodul/obs.shared/componenten/faq/faq.service';
import {filter} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class RouterService {

  constructor(
    private kunde_service: KundeService,
    private message_service: MitteilungsService,
    private revisions_service: RevisionService,
    public platform: Platform,
    private router: Router,
    private faqService: FaqService,
    ){}

  public nav_count: number = 0

  public revisionsKontrolle(url){
    if (new RouterListe().mandant.find((rev_url) => { return rev_url == url })) {
      this.revisions_service.revision_mandant('mandant/get')
    }
    if (new RouterListe().kunde.find((rev_url) => { return rev_url == url })) {
      this.revisions_service.revision_kunde('kunde/get')
    }
  }

  public regLoeschen(){
    if (!this.router.url.includes('register')){
      window.localStorage.removeItem('registrierungForm')
    }
  }

  public navCounter(){
    this.nav_count++
    this.kunde_service._kunde_get().subscribe((kunde) => {
      if (kunde) {
        if (this.nav_count == 5) {
          this.kunde_service.kunde_logout(kunde.kundeId).subscribe(() => {
            this.router.navigateByUrl('/login')
            this.message_service.setMitteilung("8804")
          })
        }
      }
    })
    timer(3000).subscribe(() => {
      this.nav_count = 0;
    })
  }

  public mitteilungWbpAus(){
    if (this.message_service.mitteilungen.value){
      this.message_service.mitteilungen.next(false);
    }
    // this.revisions_service.revision_mandant('mandant/get' )
  }

  public navController(){
    /**if (this.platform.ANDROID || this.platform.IOS){
      if (!this.router.url.match('reset_password') && !this.router.url.match('verify_email')){
        const neuerLink = window.location.href.split('/#/');
        let mobilLink = neuerLink[0] + '/mobil'
        if (neuerLink[1] !== ''){
          mobilLink += '/#/' + neuerLink[1]
        }
        alert(mobilLink)
        window.location.href = mobilLink
      }
    }**/
  }

  public startNavigation(mandant, mitteilung){
    let navigation = mandant.navigation_root_default
    if (navigation == 'home') {
      this.router.navigateByUrl('/home');
    }
    else if (navigation == 'login') {
      this.router.navigateByUrl('/login');
    }
    else {
      this.router.navigateByUrl('/speiseplan-overview');
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.message_service.setMitteilung(mitteilung);
        this.faqService.faqAnzeigen(null)
      }
    })
  }
}
