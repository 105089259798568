<div id="footer">
  <div id="footer-menu">
    <a *ngIf="rechts_content && rechts_content[0].content" mat-menu-item [routerLink]="'/rechtliches'"
      [queryParams]="{id: 'AGB'}" class="footerMenuPunkt" [class.aktiv]="seite == 'AGB'">
      <span>AGB</span>
    </a>
    <a *ngIf="rechts_content && rechts_content[1].content" mat-menu-item [routerLink]="'/rechtliches'"
       [queryParams]="{id: 'Datenschutz'}" class="footerMenuPunkt" [class.aktiv]="seite == 'Datenschutz'">
      <span>Datenschutz</span>
    </a>
    <a *ngIf="rechts_content && rechts_content[2].content" mat-menu-item [routerLink]="'/rechtliches'"
      [queryParams]="{id: 'Widerruf'}" class="footerMenuPunkt" [class.aktiv]="seite == 'Widerruf'">
      <span>Widerruf</span>
    </a>
    <a *ngIf="rechts_content && rechts_content[4]" mat-menu-item [routerLink]="'/rechtliches'"
      [queryParams]="{id: 'Impressum'}" class="footerMenuPunkt" [class.aktiv]="seite == 'Impressum'">
      <span>Impressum</span>
    </a>
    <a mat-menu-item [routerLink]="'/rechtliches'" [queryParams]="{id: 'Kontakt'}" class="footerMenuPunkt" [class.aktiv]="seite == 'Kontakt'">
      <span>Kontakt</span>
    </a>
    <a *ngIf="kuendigung != ''" mat-menu-item (click)="kuendigen();" class="footerMenuPunkt" [class.aktiv]="seite == 'Kündigung'">
      <span>Kündigung</span>
    </a>
    <a *ngIf="faq" (click)="faq_service.checkForExternalUrlFAQ()" class="footerMenuPunkt" [class.aktiv]="seite == 'FAQ'">
      <span>FAQ</span>
    </a>
  </div>
  <span id="footer-info" gitVersion>Version #33286</span>
</div>
