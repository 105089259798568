<div *ngIf="speiseplan" id="speiseplan-kunde-meldungen">
  <div *ngIf="speiseplan.bestellschluss[0].text != '' && kunde" (click)="openDialog('Bestellschlussinfo', speiseplan.bestellschluss[0].text)" class="dialog-buttons">
    <mat-icon>alarm</mat-icon>
    <span>Bestellschlussinfo</span>
  </div>
  <div *ngIf="(pdf_plaene | json) != '{}'" (click)="openPDF()" class="dialog-buttons">
    <mat-icon>picture_as_pdf</mat-icon>
    <span>Speiseplan PDF</span>
  </div>
</div>
